import { FC } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import style from './DatePicker.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { DropdownMenu, Icon, IconButton } from '@kontentino/ui';
import clsx from 'clsx';
import DatePickerPlaceholder from './DatepickerPlaceholder';
import { useUser } from 'modules/user/userSelector';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar';

type Props = ReactDatePickerProps<boolean> & {
  isDisabled?: boolean;
  isSelected?: boolean;
  hideDefaultDatePicker?: boolean;
  label: string;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  refreshKey?: string;
};

const DatePicker: FC<Props> = ({
  isOpen,
  onOpenChange,
  isDisabled,
  isSelected,
  hideDefaultDatePicker,
  label,
  refreshKey,
  children,
  ...props
}) => {
  const user = useUser();

  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={onOpenChange}>
      <DropdownMenu.Trigger>
        <div>
          <div className="tw-hidden lg:tw-flex">
            <DatePickerPlaceholder
              label={label}
              isOpen={isOpen}
              isSelected={isSelected}
              isDisabled={isDisabled}
            />
          </div>

          <IconButton
            variant="secondary"
            icon={<Icon icon={faCalendar} />}
            className="tw-flex tw-h-9 lg:tw-hidden"
            data-cy="datepicker-placeholder"
            size="medium"
          />
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={clsx(
            style.datePicker,
            'tw-top-1 !tw-z-[999] tw-gap-y-0 tw-p-0',
          )}
        >
          {!hideDefaultDatePicker && (
            <ReactDatePicker
              {...props}
              key={refreshKey}
              inline
              customInput={<></>}
              renderDayContents={(day) => <span className="day">{day}</span>}
              calendarStartDay={user.useMeridianTime ? 1 : 0}
            />
          )}
          {children}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default DatePicker;
